
import axios from 'axios';
import config from './config';
import { message } from 'antd';

axios.defaults.timeout = 60 * 2 * 1000;

const http = (opt) => {
    const option = {
        url: config.api + opt.url,
        method: opt.method || 'get'
    };

    if (option.method === 'get') {
        option.params = opt.data
    } else {
        option.data = opt.data
    }

    axios.interceptors.request.use(config => {
        // Toast.loading('Loading...');
        return config
    }, error => {
        return Promise.reject(error)
    });

    // http响应拦截器
    axios.interceptors.response.use(data => {
        // Toast.hide();
        return data
    }, error => {
        return Promise.reject(error)
    })

    return new Promise((resolve, reject) => {
        axios(option)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if(error.error) {
                    const errorMsg = error.errorMsg;
                    console.log(errorMsg);
                }

                if (error.response && error.response.status === 401) {
                    message.warn('登錄失效，請重新登錄');
                    localStorage.removeItem('Authorization');
                    //todo Rediect To /login
                    return;
                }

                if (error.response) {
                    reject(error.response.data)
                }
            });

    })

}

export default http;