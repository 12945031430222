import LoadableComponent from './utils/LoadableComponent';

const Sentence = LoadableComponent(() => import('./pages/SentenceAdmin/Sentence'));
const SentenceModal = LoadableComponent(() => import('./pages/SentenceAdmin/Sentence/Modal/index'));
const Response = LoadableComponent(() => import('./pages/SentenceAdmin/Response'));
const ResponseModal = LoadableComponent(() => import('./pages/SentenceAdmin/Response/Modal'));
const Scene = LoadableComponent(() => import('./pages/SentenceAdmin/Scene'));
const SceneModal = LoadableComponent(() => import('./pages/SentenceAdmin/Scene/Modal/index'));
const Classify = LoadableComponent(() => import('./pages/SentenceAdmin/Classify'));
const ClassifyModal = LoadableComponent(() => import('./pages/SentenceAdmin/Classify/Modal'));
const ContentImport = LoadableComponent(() => import('./pages/SentenceAdmin/ContentImport'));
const ResponseImport = LoadableComponent(() => import('./pages/SentenceAdmin/ResponseImport'));
const Suggestion = LoadableComponent(() => import('./pages/SentenceAdmin/Suggestion'));
const SuggestionModal = LoadableComponent(() => import('./pages/SentenceAdmin/Suggestion/Modal'));

const Word = LoadableComponent(() => import('./pages/WordAdmin/Word'));
const WordModal = LoadableComponent(() => import('./pages/WordAdmin/Word/modal'));
const SentenceRebuild = LoadableComponent(() => import('./pages/WordAdmin/SentenceRebuild'));
const RebuildModal = LoadableComponent(() => import('./pages/WordAdmin/SentenceRebuild/modal'));
const RebuildSuggest = LoadableComponent(() => import('./pages/WordAdmin/RebuildSuggest'));
const RebuildSuggestModal = LoadableComponent(() => import('./pages/WordAdmin/RebuildSuggest/modal'));
const WordImport = LoadableComponent(() => import('./pages/WordAdmin/ContentImport'));

const News = LoadableComponent(() => import('./pages/PageAdmin/News'));
const NewsModal = LoadableComponent(() => import('./pages/PageAdmin/News/Modal'));
const Other = LoadableComponent(() => import('./pages/PageAdmin/Other'));
const OtherModal = LoadableComponent(() => import('./pages/PageAdmin/Other/Modal'));
const Terms = LoadableComponent(() => import('./pages/PageAdmin/Terms'));
const PrivacyPolicy = LoadableComponent(() => import('./pages/PageAdmin/PrivacyPolicy'));

const Admin = LoadableComponent(() => import('./pages/Admin'));
const Profile = LoadableComponent(() => import('./pages/Profile'));
const Report = LoadableComponent(() => import('./pages/Report'));
const SettingStart = LoadableComponent(() => import('./pages/Setting/Start'));
const SettingTranslate = LoadableComponent(() => import('./pages/Setting/Translate'));
const Advice = LoadableComponent(() => import('./pages/Advice'));

const AdminModal = LoadableComponent(() => import('./pages/Admin/modal'));

const Logout = LoadableComponent(() => import('./pages/Logout'));

const routers = [
    {
        title: '句子管理',
        icon: 'laptop',
        key: '/sentence-admin',
        access: [1, 2],
        subs: [
            { key: '/sentence-admin/sentence', title: '手语句子', icon: '', needLogin: true, component: Sentence, exact: true },
            { key: '/sentence-admin/sentence/:isEdit/:id?/:isCopy?', title: '', icon: '', needLogin: true, component: SentenceModal, isModal: true },
            { key: '/sentence-admin/response', title: '手語回答', icon: '', needLogin: true, component: Response, exact: true },
            { key: '/sentence-admin/response/:isEdit/:id?/:isCopy?', title: '', icon: '', needLogin: true, component: ResponseModal, isModal: true },
            { key: '/sentence-admin/suggestion', title: '句子建議', icon: '', needLogin: true, component: Suggestion, exact: true },
            { key: '/sentence-admin/suggestion/:isEdit/:id?', title: '', icon: '', needLogin: true, component: SuggestionModal, isModal: true },
            { key: '/sentence-admin/scene', title: '情景管理', icon: '', needLogin: true, component: Scene, exact: true },
            { key: '/sentence-admin/scene/:isEdit/:id?/:isCopy?', title: '', icon: '', needLogin: true, component: SceneModal, isModal: true },
            { key: '/sentence-admin/classify', title: '情況管理', icon: '', needLogin: true, component: Classify, exact: true },
            { key: '/sentence-admin/classify/:isEdit/:id?/:isCopy?', title: '', icon: '', needLogin: true, component: ClassifyModal, isModal: true },
            { key: '/sentence-admin/content_import', title: '句子導入', icon: '', needLogin: true, component: ContentImport, exact: true },
            { key: '/sentence-admin/response_import', title: '回答導入', icon: '', needLogin: true, component: ResponseImport, exact: true }
        ]
    },
    {
        title: '字詞管理',
        icon: 'bars',
        key: '/word-admin',
        access: [1, 2],
        subs: [
            { key: '/word-admin/word', title: '手語字詞', icon: '', needLogin: true, component: Word, exact: true },
            { key: '/word-admin/word/:isEdit/:id?/:isCopy?', title: '', icon: '', needLogin: true, component: WordModal, isModal: true },
            { key: '/word-admin/sentence-rebuild', title: '重組句子', icon: '', needLogin: true, component: SentenceRebuild, exact: true },
            { key: '/word-admin/sentence-rebuild/:id?', title: '重組句子', icon: '', needLogin: true, component: RebuildModal, isModal: true },
            { key: '/word-admin/rebuild-suggestion', title: '字詞建議', icon: '', needLogin: true, component: RebuildSuggest, exact: true },
            { key: '/word-admin/rebuild-suggestion/:id?', title: '', icon: '', needLogin: true, component: RebuildSuggestModal, isModal: true },
            { key: '/word-admin/content_import', title: '字詞導入', icon: '', needLogin: true, component: WordImport, exact: true }
        ]
    },
    {
        title: '統計報告',
        icon: 'stock',
        key: '/report',
        access: [1, 3],
        needLogin: true,
        component: Report
    },
    {
        title: '頁面管理',
        icon: 'file-text',
        key: '/page-admin',
        access: [1, 2],
        subs: [
            { key: '/page-admin/advice', title: '意見建議', icon: '', needLogin: true, component: Advice, exact: true },
            { key: '/page-admin/other', title: '其他資料', icon: '', needLogin: true, component: Other, exact: true },
            { key: '/page-admin/other/:isEdit/:id?/:isCopy?', title: '', icon: '', needLogin: true, component: OtherModal, isModal: true },
            { key: '/page-admin/news', title: '手語資訊', icon: '', needLogin: true, component: News, exact: true },
            { key: '/page-admin/news/:isEdit/:id?', title: '', icon: '', needLogin: true, component: NewsModal, isModal: true },
            { key: '/page-admin/translate', title: '翻譯員名單', icon: '', needLogin: true, component: SettingTranslate },
            { key: '/page-admin/terms', title: '使用條款', icon: '', needLogin: true, component: Terms },
            { key: '/page-admin/privacy_policy', title: '隱私條款', icon: '', needLogin: true, component: PrivacyPolicy },
            { key: '/page-admin/start', title: '啟動畫面', icon: '', needLogin: true, component: SettingStart }
        ]
    },
    {
        title: '管理員列表',
        icon: 'team',
        key: '/system/admin',
        access: [1],
        needLogin: true,
        exact: true,
        component: Admin
    },
    {
        title: '管理員列表',
        key: '/system/admin/:isEdit/:id?',
        needLogin: true,
        access: [1],
        component: AdminModal,
        isModal: true
    },
    {
        title: '用戶設定',
        icon: 'user',
        key: '/profile',
        access: [1],
        needLogin: true,
        component: Profile
    },
    {
        title: '登出系統',
        icon: 'logout',
        key: '/logout',
        access: [1, 2, 3],
        needLogin: true,
        component: Logout
    }
]

export default routers;