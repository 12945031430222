import React from 'react';
import http from '../../utils/http';
import config from '../../utils/config';

class Captcha extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            image: '',
            token: ''
        }

        this.getCaptcha = this.getCaptcha.bind(this);
    }

    async getCaptcha() {
        const result = await http({
            url: config.url.login.getCaptcha,
            method: 'post'
        });

        const token = result.data.token;
        const image = result.data.image;

        this.setState({
            token,
            image
        });
    }

    componentDidMount() {
        this.getCaptcha();
    }

    render() {
        return (
            <div className="captcha-image">
                <img alt="驗證碼" src={this.state.image} onClick={this.getCaptcha} />
            </div>
        )
    }
}

export default Captcha;