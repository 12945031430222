import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Menu, Icon } from 'antd'

//此组件的意义就是将数据抽离出来，通过传递数据去渲染
@withRouter
class CustomerMenu extends React.Component {

    constructor(prop) {
        super(prop);

        const role = localStorage.getItem("role");

        this.state = {
            role,
            openKeys: [],
            selectedKeys: []
        };
    }

    componentDidMount() {
        this.setRank();
    }

    setRank = () => {
        let pathname = this.props.location.pathname;

        if (pathname === '/') {
            pathname = '/sentence-admin/sentence';
        }

        //获取当前所在的目录层级
        let rank = this.getRank(pathname);

        switch (rank.length) {
            case 2:  //一级目录
                this.setState({
                    selectedKeys: [pathname]
                })
                break;
            default:
                this.setState({
                    selectedKeys: [rank.slice(0, 3).join('/')],
                    openKeys: [rank.slice(0, 2).join('/')]
                })
        }
    }

    getRank = (pathname) => {
        let rank = pathname.split('/');
        if (rank.length > 3) {
            rank = rank.slice(0, 3);
        }

        return rank;
    }

    componentWillReceiveProps(nextProps) {
        //当点击面包屑导航时，侧边栏要同步响应
        const pathname = nextProps.location.pathname
        if (this.props.location.pathname !== pathname) {

            let rank = this.getRank(pathname);

            this.setState({
                selectedKeys: [rank.slice(0, 3).join('/')]
            })
        }
    }

    onOpenChange = (openKeys) => {
        //此函数的作用只展开当前父级菜单（父级菜单下可能还有子菜单）
        if (openKeys.length === 0 || openKeys.length === 1) {
            this.setState({
                openKeys
            })
            return
        }

        //最新展开的菜单
        const latestOpenKey = openKeys[openKeys.length - 1]

        //判断最新展开的菜单是不是父级菜单，若是父级菜单就只展开一个，不是父级菜单就展开父级菜单和当前子菜单
        //因为我的子菜单的key包含了父级菜单，所以不用像官网的例子单独定义父级菜单数组，然后比较当前菜单在不在父级菜单数组里面。
        //只适用于3级菜单
        if (latestOpenKey.includes(openKeys[0])) {
            this.setState({
                openKeys
            })
        } else {
            this.setState({
                openKeys: [latestOpenKey]
            })
        }
    }

    renderMenuItem = ({ key, icon, title, }) => {
        return (
            <Menu.Item key={key}>
                <Link to={key}>
                    {icon && <Icon type={icon} />}
                    <span>{title}</span>
                </Link>
            </Menu.Item>
        )
    }

    renderSubMenu = ({ key, icon, title, subs }) => {
        subs = subs && subs.filter((item) => {
            return !item.isModal;
        });

        return (
            <Menu.SubMenu key={key} title={<span>{icon && <Icon type={icon} />}<span>{title}</span></span>}>
                {
                    subs && subs.map(item => {
                        return item.subs && item.subs.length > 0 ? this.renderSubMenu(item) : this.renderMenuItem(item)
                    })
                }
            </Menu.SubMenu>
        )
    }

    render() {
        const { role, openKeys, selectedKeys } = this.state;

        let { menus } = this.props;
        menus = menus && menus.filter((item) => {
            if (role != 0 && (!item.access || item.access.indexOf(parseInt(role)) === -1)) {
                return false;
            }

            return !item.isModal;
        });

        return (
            <Menu
                onOpenChange={this.onOpenChange}
                onClick={({ key }) => this.setState({ selectedKeys: [key] })}
                openKeys={openKeys}
                selectedKeys={selectedKeys}
                theme={this.props.theme ? this.props.theme : 'dark'}
                mode='inline'>
                {
                    menus && menus.map(item => {
                        return item.subs && item.subs.length > 0 ? this.renderSubMenu(item) : this.renderMenuItem(item)
                    })
                }
            </Menu>
        )
    }
}

export default CustomerMenu;