import React from 'react'
import CustomMenu from "../CustomMenu/index";
import router from './../../routers';


class SiderNav extends React.Component {
  render() {

    return (
      <div className="sider-nav">
        <div className="logo">EarConnect</div>
        <CustomMenu menus={router}/>
      </div>
    )
  }
}

export default SiderNav;