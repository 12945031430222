import type from './types';

const initialState = {
    scenes: [],
};

const sceneResucer = (state = initialState, action) => {
    switch (action.type) {
        case type.LOAD_SCENES:
            return {
                ...state,
                scenes: action.payload
            }
    
        default:
            return state;
    }
}

export default sceneResucer;