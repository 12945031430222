import React from 'react'
import { Layout, Icon } from 'antd';
import MainContent from '@components/MainContent'
import SiderNav from '@components/SiderNav'


const { Sider, Header, Content } = Layout;

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collapsed: false
        };
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    render() {
        return (
            <div className='managment-page'>
                <Layout>
                    <Sider collapsible
                        trigger={null}
                        collapsed={this.state.collapsed}>
                        <SiderNav />
                    </Sider>

                    <Layout>
                        <Header style={{ background: '#fff', padding: 0 }}>
                            <Icon
                                className="trigger"
                                type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                                onClick={this.toggle}
                            />
                        </Header>
                        <Content
                            style={{
                                margin: '24px 16px',
                                background: '#fff',
                                minHeight: 280,
                            }}
                        >
                            <MainContent/>
                        </Content>
                    </Layout>
                </Layout>
            </div>
        );
    }
}

export default Index