import type from './types';

const initialState = {
    categoryList: [],
};

const cateforyResucer = (state = initialState, action) => {
    switch (action.type) {
        case type.LOAD_CATEGORY:
            return {
                ...state,
                categoryList: action.payload
            }
    
        default:
            return state;
    }
}

export default cateforyResucer;