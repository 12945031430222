import type from './types';

const initialState = {
    sentenceList: [],
};

const respondReducer = (state = initialState, action) => {
    switch (action.type) {
        case type.LOAD_SENTENCE:
            return {
                ...state,
                sentenceList: action.payload
            }
    
        default:
            return state;
    }
}

export default respondReducer;