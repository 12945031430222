import React from 'react';
import 'antd/dist/antd.min.css';
import './style/app.scss';
import { Route, Switch, withRouter } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'
import Login from './pages/Login'
import HomePage from './pages/HomePage';
import Notfound from './pages/NotFound';

function App() {
    return (
        <Switch>
            <Route exact path="/login" component={Login} />
            <PrivateRoute path='/' component={HomePage} />
            <Route component={Notfound} />
        </Switch>
    )
}

export default withRouter(App);