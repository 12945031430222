import { createStore, combineReducers, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunkMiddleware from 'redux-thunk'
import sceneReducer from './../redux/scene/reducer';
import cateforyResucer from './../redux/category/reducer';
import sentenceReducer from './../redux/sentence/reducer';
import respondReducer from './../redux/respond/reducer';

const rootReducer = combineReducers({
    sceneReducer,
    cateforyResucer,
    sentenceReducer,
    respondReducer
});

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware, logger));

export { store };