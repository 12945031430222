import React from 'react';
import Base64 from 'base-64';
import { Form, Input, Button, Alert, message } from 'antd';
import http from './../../utils/http';
import { Redirect } from "react-router-dom";
import config from './../../utils/config';
import { setAuthToken } from '../../utils/Session';
import Captcha from './Captcha';

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showError: false,
            errorMsg: '',
            loginFlag: false,
            token: ''
        };
    }

    handleSubmit = e => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }

            const loading = message.loading('登录中...', 0);

            http({
                url: config.url.login.session,
                method: 'post',
                data: {
                    username: values.username,
                    password: values.password,
                    code: values.code,
                    token: this.Captcha.state.token
                }
            }).then(result => {
                this.setState({
                    showError: false,
                    loginFlag: true
                });

                setTimeout(loading, 0);

                const token = result.data.token;
                const userData = JSON.parse(Base64.decode(token.split(".")[1]));

                localStorage.setItem("role", userData.role);
                localStorage.setItem('Authorization', token);

                setAuthToken(() => {
                    const { from } = this.props.location.state || { from: { pathname: '/' } };
                    this.props.history.push(from);
                });
            }, error => {
                this.setState({
                    showError: true,
                    errorMsg: error.errorMsg
                });

                this.Captcha.getCaptcha();

                setTimeout(loading, 0);

                localStorage.removeItem('Authorization');
            });
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        if (this.state.loginFlag || localStorage.getItem('Authorization')) {
            return <Redirect to={{ pathname: "/" }} />;
        }

        return (
            <div className='login-page'>
                <div className='container'>
                    <h3 className='title'>EarConnect</h3>
                    <Form onSubmit={this.handleSubmit} className="login-form" autoComplete="off">
                        <Form.Item>
                            {getFieldDecorator('username', {
                                rules: [{ required: true, message: '請輸入用戶名' }],
                            })(
                                <Input placeholder="用戶名" autoComplete="off" />,
                            )}
                        </Form.Item>

                        <Form.Item>
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: '請輸入密碼' }],
                            })(
                                <Input placeholder="密碼" type="password" autoComplete="off" />
                            )}
                        </Form.Item>

                        <div className="captcha-box">
                            <Form.Item>
                                {getFieldDecorator('code', {
                                    rules: [{ required: true, message: '請輸入驗證碼' }],
                                })(
                                    <Input placeholder="驗證碼" />
                                )}
                            </Form.Item>

                            <Captcha ref={(n) => {
                                this.Captcha = n;
                            }} />
                        </div>

                        {this.state.showError ? (
                            <Alert message={this.state.errorMsg} type="error" />
                        ) : null}

                        <div className='bottom'>
                            <Button type="primary" htmlType="submit" className="login-form-button">登錄</Button>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }
}

const LoginForm = Form.create()(Login);
export default LoginForm;
