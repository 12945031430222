import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PrivateRoute from './../../components/PrivateRoute';
import routers from './../../routers';

class MainContent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            role: localStorage.getItem("role")
        };
    }

    render() {
        const { role } = this.state;

        const accessRouters = routers.filter((route) => {
            if (role == 0 || (route.access && route.access.indexOf(parseInt(role)) != -1)) {
                return true;
            }

            return false;
        });

        return (
            <div className='main-content'>
                <Switch>
                    {
                        accessRouters.map((route, i) => {
                            if (route.component) {
                                return <PrivateRoute path={route.key} key={i} {...route} />
                            }

                            return route.subs && route.subs.map((route, i) => {
                                return <PrivateRoute path={route.key} key={i} {...route} />
                            });
                        })
                    }
                    <Redirect exact from='/' to='/sentence-admin/sentence' />
                </Switch>
            </div>
        );
    }
}

export default MainContent;