
const config = {
    api: {
        production: 'https://api.admin.earconnect.hk/',
        development: 'https://api.admin.earconnect.hk/',
        test: window.location.origin,
    }[process.env.NODE_ENV || 'development'],
    url: {
        login: {
            getCaptcha: 'captcha',
            session: 'session'
        }
        
    }
};

export default config;