import axios from "axios";

const setAuthToken = (callback) => {
    const token = localStorage['Authorization'];
    if (token) {
        axios.defaults.headers.common['Authorization'] = token;
        callback && callback();
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
}

const authHeader = () => {
    return {
        Authorization: localStorage['Authorization']
    };
};


const isAuthenticated = () => {
    return !!localStorage['Authorization'];
};

export { setAuthToken, isAuthenticated, authHeader };