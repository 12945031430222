import React from 'react';

class NotFound extends React.Component {
    render() {
        return (
            <div className='not-found-page'>
                404 page
            </div>
        );
    }
}

export default NotFound