import type from './types';

const initialState = {
    respondList: [],
};

const respondReducer = (state = initialState, action) => {
    switch (action.type) {
        case type.LOAD_RESPOND:
            return {
                ...state,
                respondList: action.payload
            }
    
        default:
            return state;
    }
}

export default respondReducer;